<template>
	<ToolsResearchPaper></ToolsResearchPaper>
</template>

<script>
import ToolsResearchPaper from "@/components/shared/tools/researchPaper/Index.vue";

export default {
	name: "CollaborateResearchPaper",

	components: {
		ToolsResearchPaper,
	},

	mounted() {
		this.getResearchPaper();
	},

	methods: {
		async getResearchPaper() {
			var response = await this.$store.dispatch(
				"toolsResearchPaper/getTemplate",
				{
					clear: false,
					student_id: this.$route.params.id.split("-")[1],
				},
			);
		},
	},
};
</script>

<style></style>
